$webfont-path: '../Fonts';

@font-face {
  font-family: 'Palanquin';
  src: url('#{$webfont-path}/palanquin/Palanquin-Regular.eot'); /* IE9 Compat Modes */
  src: local('Palanquin Regular'), local('Palanquin-Regular'),
  url('#{$webfont-path}/palanquin/Palanquin-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$webfont-path}/palanquin/Palanquin-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$webfont-path}/palanquin/Palanquin-Regular.woff') format('woff'), /* Modern Browsers */
  url('#{$webfont-path}/palanquin/Palanquin-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$webfont-path}/palanquin/Palanquin-Regular.svg#Palanquin-Regular') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Palanquin';
  src: url('#{$webfont-path}/palanquin/Palanquin-SemiBold.eot'); /* IE9 Compat Modes */
  src: local('Palanquin SemiBold'), local('Palanquin-SemiBold'),
  url('#{$webfont-path}/palanquin/Palanquin-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$webfont-path}/palanquin/Palanquin-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$webfont-path}/palanquin/Palanquin-SemiBold.woff') format('woff'), /* Modern Browsers */
  url('#{$webfont-path}/palanquin/Palanquin-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$webfont-path}/palanquin/Palanquin-SemiBold.svg#Palanquin-SemiBold') format('svg'); /* Legacy iOS */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Palanquin';
  src: url('#{$webfont-path}/palanquin/Palanquin-Regular_1.eot'); /* IE9 Compat Modes */
  src: local('Palanquin Regular_1'), local('Palanquin-Regular_1'),
  url('#{$webfont-path}/palanquin/Palanquin-Regular_1.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$webfont-path}/palanquin/Palanquin-Regular_1.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$webfont-path}/palanquin/Palanquin-Regular_1.woff') format('woff'), /* Modern Browsers */
  url('#{$webfont-path}/palanquin/Palanquin-Regular_1.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$webfont-path}/palanquin/Palanquin-Regular_1.svg#Palanquin-Regular') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Palanquin';
  src: url('#{$webfont-path}/palanquin/Palanquin-Medium.eot'); /* IE9 Compat Modes */
  src: local('Palanquin Medium'), local('Palanquin-Medium'),
  url('#{$webfont-path}/palanquin/Palanquin-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$webfont-path}/palanquin/Palanquin-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$webfont-path}/palanquin/Palanquin-Medium.woff') format('woff'), /* Modern Browsers */
  url('#{$webfont-path}/palanquin/Palanquin-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$webfont-path}/palanquin/Palanquin-Medium.svg#Palanquin-Medium') format('svg'); /* Legacy iOS */
  font-weight: 500;
  font-style: normal;
}