.asaf-member-benefits {

  .tab-content {
    background: $asaf-grey-lightest;
  }

  .nav-tabs, .nav-link.active, .nav-link:hover, .nav-link.active:hover {
    background: transparent;
    border: 1px solid transparent;
    color: #fff;
    text-align: center;

    a {
      color: #fff;
    }
  }

  .nav-item {
    position: relative;
    width: 33.33333%;

    a {
      color: #fff;
    }

    &.asaf-blue {
      background: $asaf-blue-light;
    }

    &.asaf-green {
      background: $primary;

    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: rem-calc(3);
    }
  }
}

.asaf-text-black-left {
  color: $body-color !important;
  text-align: left;

  a {
    color: $primary !important;
  }
}

.nav-link.active {
  $asaf-triangle-size: rem-calc(2);

  .asaf-triangle {
    &:after {
      content: " ";
      position: absolute;
      left: 40%;
      bottom: -$asaf-triangle-size;
      border-top: $asaf-triangle-size solid $asaf-blue-light;
      border-right: $asaf-triangle-size solid transparent;
      border-left: $asaf-triangle-size solid transparent;
      border-bottom: none;
    }

    &.green:after {
      border-top: $asaf-triangle-size solid $primary;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.asaf-benefit-text {
  font-size: 1.3rem;
}

.asaf-two-column {
  column-count: 2;
  column-gap: 30px;

  ul {
    margin: 0;
    padding: 0;
  }
}