#asaf-nav-mobile {
  border-left: 1px solid $asaf-grey-light;
}

.mm-panels {
  font-weight: 400;
}

.asaf-community-mobile-button {
  max-width: 100%;
  border: 2px solid $asaf-blue;
  border-radius: $btn-border-radius;
  padding: 0.25rem 2rem;
  background: transparent;
  color: $asaf-blue;
  font-weight: 600;
  &:hover {
    background: $asaf-blue;
    color: #fff;
  }
}

.asaf-mobile-search-form-input-wrapper {
  width: 320px;
  position: relative;
  input {
    padding: 1.5rem 0.5rem;
    border: 1px solid $asaf-grey-light;
    background: $asaf-grey-lightest;
    &::placeholder {
      color: $asaf-grey;
      font-weight: 500;
    }
  }
  i {
    color: $asaf-grey;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 0 .5rem;
  }

}

#mm-0 .mm-navbar_sticky {
  display: none;
  height: 0;
}