.asaf-button-outline {
  color: $primary;
  background-color: #fff;
  font-weight: 600;
}

.asaf-button-become-part {
  color: #fff;
  background-color: transparent;
  font-weight: 600;
  border: 2px solid #fff;

  &:hover {
    color: $primary;
    background: #fff;
    border: 2px solid #fff;
  }
}

.asaf-button-blue {
  color: $asaf-blue;
  background-color: #fff;
  border: 2px solid $asaf-blue;

  &:hover {
    background: $asaf-blue-light;
    border: 2px solid $asaf-blue-light;
  }
}