.asaf-news-search-form {
  background: $asaf-blue-light;

  color: $asaf-white;

  .form-control {
    background: $asaf-blue-lightest;
    color: $asaf-white;
    font-weight: 500;
  }

  .form-control::placeholder {
    color: $asaf-white!important;
    font-weight: 500;
  }

  .font-weight-medium {
    font-weight: 500;
  }

  @include media-breakpoint-down(md) {
    select {
      margin-bottom: 0.5rem;
    }
    .asaf-button-search {
      margin-top: 1rem;
    }
  }
}

.asaf-button-search {
  padding: 0rem 1.2rem;
  background: $asaf-green;
  border: none;
  color: $asaf-white;
  font-weight: 500;
}

.asaf-button-search:hover {
  background: $asaf-yellow;
}