.asaf-breadcrumb {

  margin-top: $navbar-height;

  /* margin: rem-calc(2 0); */
  background-color: $asaf-blue-light;

  li.breadcrumb-item,
  li.breadcrumb-item::before {
    color: $asaf-white;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: "|";
    color: $asaf-white;
  }

  a {
    color: $asaf-white;
    text-decoration: underline;
  }
  a:hover, a:active {
    color: $asaf-white;
    text-decoration: none;
  }

}






