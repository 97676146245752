footer.asaf-page-footer {

  background-image: url(../Images/footer-background.png);
  background-repeat: repeat-x;
  background-position: bottom -100px center;

  /* min-height: 900px;*/
  margin-top: 20px;

  color: $asaf-grey;

  strong {
    font-weight: 600;
  }

  .plus {
    font-size: 2.5rem;
    padding: 0 1.3rem;
    line-height: 40px;
  }

  a {
    color: $asaf-grey;
  }

  hr {
    border-top: 2px solid #fff;
    margin: rem-calc(5 0);
  }

  @include media-breakpoint-down(md) {
    padding-top: rem-calc(4);
  }
}

.asaf-footer-intro {
  font-weight: 500;
  font-size: 1.75rem;
  padding-bottom: 2rem;
  color: $asaf-white;
}

.asaf-footer-newsletter-form-container {
  padding: 30px 0 60px 0;
  margin-bottom: 50px;
  background-color: $asaf-blue-light;
}

.asaf-footer-newsletter-form {

  input {
    padding: 25px;
    border: none;

    &::placeholder {
      color: $asaf-grey;
      text-align: center;
    }

    background: $asaf-white;
    border-radius: 0;
  }

  .asaf-input-spacing {
    margin-right: 10px;
  }

  input {
    margin-bottom: 5px;
  }

  .btn-light {
    padding: 11px 0;
    width: 100%;
    color: $asaf-white;
    font-weight: 500;
    border:none;

    background: $asaf-green;

    &:hover {
      color: #fff;
      background: $asaf-yellow;
    }
  }

  @include media-breakpoint-down(sm) {
    .asaf-input-spacing {
      margin-right: 0;
    }
  }


  .checkbox-label {
    padding: 0 0 0 10px;
    width: 400px;
    border: none;
    background: none;
    color: $asaf-white;
    float:left;
  }

  .input-group-text {
    float:left;
  }

}

.asaf-footer-search-form {
  float: right;
  width: rem-calc(20);
  margin-top: 10px;

  input {
    padding: 25px;
    border: none;

    &::placeholder {
      color: $asaf-blue;
      font-weight: 500;
    }
  }

}

.asaf-footer-search-form-input-wrapper {
  position: relative;
  i {
    color: $asaf-blue;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 0 .5rem;
  }

}

.asaf-footer-content {
  font-weight: 500;
  font-size: 1.2rem;
}

.asaf-footer-nav {

  display: block!important;

  .nav-item {
    font-size: 1rem;
    font-weight: normal;
  }

  .nav-link {
    padding: 0.5rem 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

.asaf-footer-nav.asaf-footer-nav-related-links {
  .nav-link:before {
    padding-right: 0.5rem;
    font-family: 'Font Awesome 5 Pro';
    content: "\f35d";
  }
}

.asaf-footer-logo {
  margin-bottom: rem-calc(3.5);
}

.asaf-footer-address {
  margin-bottom: rem-calc(3.5);
}

.asaf-footer-socialicons {
  float: right;
  @include media-breakpoint-down(md) {
    float: none;
  }

  a {
    margin-right: 10px;
  }
}

.asaf-footer-padding {
  /*padding-bottom: rem-calc(8.8);*/
}

.asaf-footer-bottom {
  padding-top: 20px;
  font-size: 0.9rem;
  background: $asaf-blue;
  color: $asaf-white;

  p {
    padding: 0;
    @include media-breakpoint-down(md) {
      text-align: center !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .asaf-footer-logo, .asaf-footer-address, .asaf-footer-socialicons {
    text-align: center;
  }
  .asaf-footer-address, .asaf-footer-socialicons {
    border-bottom: 2px solid #fff;
    padding-bottom: rem-calc(3.5);
  }
  .nav.asaf-footer-nav {
    margin: rem-calc(0 0 0 0);
    display: block;
  }
  .asaf-footer-nav, .asaf-footer-search-form {
    float: inherit;
    margin: 0 auto;
    max-width: 90%;
  }
}

#mc_embed_signup div.mce_inline_error {
  background-color: #ff8000 !important;
  font-weight: 300 !important;
  font-style: italic;
}

#mce-responses, #mce-responses a {
  color: $asaf-white !important;
  font-weight: 500;
}

.oceans-flag {
  position: relative;
  top: -2px;
}
