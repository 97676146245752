.datepicker-dropdown {
  th, td {
    color: $asaf-white;
  }
  td.old,
  td.new {
    color: #ccc!important;
  }
  td:hover,
  th:hover {
    background: $asaf-blue-light!important;
  }
}
