.asaf-accordion {
  h3 {
    &:hover {
      cursor: pointer;
    }
  }

  .asaf-caret-right,
  .asaf-caret-down {
    width: 15px;
  }
  .asaf-caret-down {
    position: relative;
    left: -3px;
  }

  h3[aria-expanded="true"] {
    .fa-caret-right {
      display: none;
    }
  }

  h3[aria-expanded="false"] {
    .fa-caret-down {
      display: none;

    }
  }
}