.asaf-pageintro {
  h1 {
    @include font-size(3.75rem);
  }
  h2 {
    @include font-size(3.75rem);
    color: $body-color;
  }
  h3 {
    @include font-size(2.5rem);
  }

}

.asaf-pageintro__subtitle {
  @include font-size(1.25rem);
  color: $body-color;
  font-weight: 400!important;
}

.pagets__start {
  .asaf-pageintro {
    h3 {
      margin-top: 8rem;
    }
  }
}