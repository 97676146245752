
.asaf-schedule-slider {
  .slick-next,
  .slick-prev {
    top: 12rem;
  }
  .slick-dots {
    bottom: -50px;
  }


  .slick-next::before,
  .slick-prev::before {
    color: #484848;
  }

  .slick-next:hover::before,
  .slick-prev:hover::before
  {
    color: #8F8E8C!important;
  }

}

.asaf-schedule-slider-elements a:hover {
  color: $asaf-white;
}

@media only screen and (max-width: 680px) {
  .asaf-schedule-slider-elements {

    .slick-next, .slick-prev {
      display: none !important;
    }
  }
}

.asaf-event-date-slider {
  width: 100%;
  min-height: 265px;
  min-width: 265px;
  color: $asaf-white;
  position: relative;
  margin-top: -10px;
  background: url(../Images/event-date-background-slider.png) no-repeat!important;


  .asaf-event-item-date__daymonth {
    padding-top:40px;
  }
  .asaf-event-item-date__daymonth_smaller {
    padding-top:40px;
  }

  /*
  .asaf-event-item-date__day {
    padding-top:30px;
    line-height: 4rem;
  }

  .asaf-event-item-date__monthyear {
    line-height: 1rem;
    font-size: 1.125rem;
  }
  */


  p {
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 400;
  }

  .fa-angle-right:before {
    font-weight: 300;
    position: absolute;
    bottom: 1.2rem;
    left: 47%;
  }




  @include media-breakpoint-down(md) {
    .text-center {
      padding-right: 5rem;
    }
    .fa-angle-right:before {
      left: 37%;
    }
  }
  @include media-breakpoint-down(sm) {
    .text-center {
      padding-right: 15rem;
    }
    .fa-angle-right:before {
      left: 25%;
    }
  }


  @media only screen and (max-width: 500px) {

  }

}

.asaf-news-element-image-slider {
  .slick-next:before,
  .slick-prev:before {
    color: $asaf-green;
  }
}

@include media-breakpoint-down(md) {
  .asaf-event-date-slider {
    margin-left: 2.7rem;
  }
}

@include media-breakpoint-down(sm) {
  .asaf-event-date-slider {
    margin-left: 7.5rem;
  }
}



@media only screen and (max-width: 500px) {
  .asaf-event-date-slider {
    margin-left: 20%;
    .text-center {
      padding-right: 10rem;
    }
  }
}

@media only screen and (max-width: 400px) {
  .asaf-event-date-slider {
    margin-left: 10%;
    .text-center {
      padding-right: 4rem;
    }
  }
}


