$asaf-header-waves-top: 140px;
$asaf-header-waves-margin-right: 150px;
$asaf-header-waves-breakpoint-to-mobile: 1280px;

.asaf-navbar.asaf-navbar-transparent {
  position: relative;
  z-index: 1000;
  background: transparent;
  margin: - $asaf-header-waves-top 0;
}

@keyframes move-wave {
  0% {
    background-position: left -1920px;
  }
  100% {
    background-position: left 0;
  }
}

.asaf-header-waves-wrapper {
  margin-top: $navbar-height;
  background: #ffffff;
}

.asaf-header-waves {
  position: relative;
  /*
  height: 800px;
   */
  max-height: 800px;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
}

.asaf-header-waves-logo {
  position: absolute;
  z-index: 999;
  top: 26px;
  left: $asaf-header-waves-margin-right;
}

.asaf-header-waves-wave {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: left bottom;
}

.asaf-header-waves-wave.white {
  z-index: 20;
  background: url(../Images/header-wave-white.png) no-repeat;
}

.asaf-header-waves-wave.green {
  z-index: 30;
  background: url(../Images/header-wave-green.png) no-repeat;
  opacity: 0.5;
  animation: move-wave 16s linear infinite;
}

.asaf-header-waves-wave.blue {
  z-index: 40;
  background: url(../Images/header-wave-blue.png) no-repeat;
  opacity: 0.5;
  animation: move-wave 32s linear infinite;
}

.asaf-header-waves-slider-image-shadow-top {
  /*
  position: absolute;
  z-index: 19;
  background: linear-gradient(rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 40%);
  height: 100%;
  width: 100%;

   */
}

.asaf-header-waves-slider-content {
  position: absolute;
  z-index: 100;
  width: 100%;
  bottom: rem-calc(2);
  text-align: center;
  /*
  left: $asaf-header-waves-margin-right;
  */

  h1 {
    font-weight: 500;
    text-transform: uppercase;
    @include font-size(7.5rem);

    color: $asaf-white;
    margin-bottom: 10px;
  }

  span {
    font-weight: 300;
    text-transform: uppercase;
  }

  p {
    font-size: 2.2rem;
    line-height: 2.8rem;
    color: $asaf-white;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0px;
  }
}

.asaf-header-waves-slider-graphic {
  position: absolute;
  bottom: 0;
  right: 15%;
  left: 15%;
  z-index: 50;
  img {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 1280px) {

  .asaf-header-waves {
    max-height: 530px;

    .white, .green, .blue {
      display: none;
    }
  }

  .asaf-header-waves-logo {
    background: url(../Images/header-waves-mobile-bg.png) no-repeat top left;
    padding: 1.75rem 0 0 1.75rem;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    img {
      width: 190px;
    }
  }
  .asaf-header-waves-slider-content {
    color: #fff;
    width: 100%;
    left: inherit;
    text-align: center;
    padding: 0 0.5rem;

    h1 {
      @include font-size(6rem);
      line-height: 6rem;
    }
  }



  .carousel-indicators {
    position: absolute;
    top: 480px;
    left: 0;
    right: 0;
    z-index: 1001;

    li {
      background-color: #fff
    }
  }
}


@include media-breakpoint-down(lg) {
  .asaf-header-waves-slider-content {
    bottom: 10px;
  }
}

@include media-breakpoint-down(md) {
  .asaf-header-waves-slider-content {

    h1 {
      @include font-size(4rem);
      line-height: 4rem;
      margin-bottom: 0px;
    }
    p {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  .asaf-header-waves-slider-content {
    h1 {
      @include font-size(3rem);
      line-height: 3rem;
    }
    p {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
}