@import "_variables";
@import "_bootstrap";

@import "Fontawesome/fontawesome";
@import "Fontawesome/regular";
@import "Fontawesome/solid";
@import "Fontawesome/light";
@import "Fontawesome/brands";

// show breakpoints for development
//@import "Development/showbreakpoint";

@import "Mixins/mixins";
@import "Mixins/functions";

@import "node_modules/mmenu-js/src/mmenu";

@import "Layout/webfonts";
@import "Layout/typography";
@import "Layout/mmenu";
@import "Layout/navbar";
@import "Layout/nav-language";
@import "Layout/navbar-toggle";
@import "Layout/breadcrumb";
@import "Layout/footer";
@import "Layout/to-top";
@import "Layout/form-icons";
@import "Layout/magnific-popup";
@import "Layout/links";
@import "Layout/lists";
@import "Layout/pagination";
@import "Layout/print";
@import "Layout/spacings";

@import "Modules/_pageintro";
@import "Modules/buttons";
@import "Modules/slick";
@import "Modules/slick-theme";
@import "Modules/schedule-slider";
@import "Modules/become-a-part";
@import "Modules/news";
@import "Modules/quotes";
@import "Modules/ambassadors";
@import "Modules/timeline";
@import "Modules/chapters";
@import "Modules/event-search";
@import "Modules/news-search";
@import "Modules/event";
@import "Modules/promokit";
@import "Modules/coordination-board";
@import "Modules/benefits";
@import "Modules/application";
@import "Modules/testimonial";
@import "Modules/statute";
@import "Modules/tables";
@import "Modules/carousel";
@import "Modules/header-waves";
@import "Modules/tabs";
@import "Modules/accordion";
@import "Modules/powermail";
@import "Modules/search";
@import "Modules/copyright";
@import "Modules/jarallax";
@import "Modules/side-icons";
@import "Modules/background";
@import "Modules/datepicker";
@import "Modules/google-map";
@import "Modules/arabic";

html, body {
  width: 100%;
  //min-height: 100%;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 320px;
}

main {
  a {
    font-weight: 500;
  }
}

.asaf-navbar {
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
}

// prevent mmenu page jump
.mm-wrapper_blocking {
  overflow: inherit;
}

.template-overview {
  #asaf-nav-mobile {
    display: none;
  }
}

.asaf-full-width-container {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

.pointer {
  cursor:pointer;
}