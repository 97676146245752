.asaf-become-part {
  color: #fff;
  overflow: hidden;
  min-height: 400px;
}

.asaf-become-part-content {
  padding: 2rem;
  @include font-size(1.25rem);
  text-align: center;
  h3 {
    font-weight: 800;
    @include font-size(2.5rem);
    line-height: 2.8rem;
    color: #fff;
  }
}

h3.asaf-become-part-header {
  @include font-size(3.75rem);
}