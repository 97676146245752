.coordination-board-member {

  .slick-slider {
    height: 100%;
  }

  .slick-dots {
    bottom: 0;
  }

  background: $asaf-grey-lightest;

  &.even, &.odd {
    .coordination-board-member-left-col {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }

  &.even {
    .coordination-board-member-left-col {
      background: $primary;
    }
  }

  &.odd {
    .coordination-board-member-left-col {
      background: $asaf-blue;
    }
  }

}

.coordination-board-member-position {
  font-size: 1.875rem;
  line-height: 2.2rem;
}

.asaf-coordination-board-archive-slider {
  .slick-slide {
    padding: 0 15px;
  }

  .slick-dots {
    bottom: rem-calc(-3);
  }
}

.asaf-coordination-board-archive-slider-item {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  a {
    color: $body-color;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    font-size: 1.75rem;
    font-weight: 300;
    position: absolute;
    top: 42%;
    width: 100%;
    text-align: center;
    margin: auto;
    line-height: 1.75rem;
  }
  &.two-lines {
    span {
      top: 38%;
    }
  }
  &.active {
    a {
      color: #fff;
    }
  }

  @media screen and (max-width: 850px) {
    span {
      font-size: 2rem;
      top: 42%;
    }
  }

  @include media-breakpoint-down(md) {
    span {
      font-size: 1.75rem;
      top: 40%;
    }
  }
}

.coordination-board-member-contact a {
  font-weight: 600;
}
