.asaf-copyright-wrapper {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
}

.asaf-copyright {
  color: $asaf-white !important;
  font-weight: 400;
  font-size: 1rem;
  position: absolute;
  bottom: 12px;
  right: 14px;
  &:before {
    content: '\00a9';
  }
}


@include media-breakpoint-down(lg) {
  .asaf-copyright {
    bottom: 0px;
  }
}