$asaf-toggler-size: 50px;

.asaf-navbar-toogle {
  position: relative;
  z-index: 1001;
}

.navbar-toggler {
  position: relative;
  top: 1rem;
  right: 1rem;
  background: #fff;
  height: $asaf-toggler-size;
  width: $asaf-toggler-size;
}

.mm-wrapper_opened {
  .navbar-toggler {
    display: none;
  }
}

.asaf-dau-menu-text {
  font-size: 1rem;
  color: #fff;
  position: absolute;
  top: 56px;
  right: 74px;
  @media only screen and (min-width: 1280px) {
      display: none;
  }
}