.nav-language {

  margin: 30px 0 0 20px;

  button.btn {
    background: $asaf-blue-light-langnav;
    color: $asaf-blue;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    width: 44px!important;
    height: 44px;
    border-radius: 40px;
    padding: 0 0 0 4px;
  }


  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0em;
    vertical-align: .15em;
    content: "";

    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }

  .dropdown-menu {
    /*display: block!important;*/
    background: $asaf-blue-light-langnav;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    min-width: 44px!important;
    overflow:hidden!important;
    padding:0!important;
    margin-top: -10px;

    color: $asaf-blue-light-langnav;
    font-size: 0.5rem;


    a {
      display: block!important;
      font-size: 0.75rem;
      color: $asaf-blue;
      font-weight: 500;
      padding: 0rem 0rem 0.1rem 0.8rem;
    }

    a.last {
      padding-bottom: 0.7rem;
    }

    a.first {
      padding-bottom: 0;
    }

  }


  .folded-out {
    button.btn {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .dropdown-toggle::after {
      border-top: 0;
      border-bottom: .3em solid;
    }
  }


}

.nav-language.nav-language-mobile {
  margin-top: 34px;
}


