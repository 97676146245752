.asaf-quotes-wrapper-outer {
  background: $asaf-yellow-testimonials!important;
}

.asaf-quotes {
  position: relative;
  width: 55%;
  margin: auto;
  max-width: 570px;
  color: $body-color;
  background: $asaf-grey-light;

  .asaf-quote-information {
    p {
      font-size: 1.25rem;
    }
  }
  .slick-list {
    img {
      display: inline-block;
    }
  }

  .slick-next {
    right: 11rem;
    top: 6rem;
  }

  .slick-prev {
    left: 11rem;
    top: 6rem;
    z-index:999;
  }

  .slick-dots {
    bottom: -45px;
  }

  @include media-breakpoint-down(md) {
    .slick-next {
      right: 28%;
    }
    .slick-prev {
      left: 28%;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}


.asaf-quotes-wrapper {
  $asaf-quote-size: 100px;

  .asaf-quote {
    position: relative;
  }

  .asaf-quote-icon {
    width: $asaf-quote-size;
    height: $asaf-quote-size;
  }

  .asaf-quote-icon-left {
    position: absolute;
    top: 6rem;
    left: rem-calc(-50);
  }

  .asaf-quote-icon-right {
    position: absolute;
    top: 1rem;
    right: rem-calc(-25);
  }
}

.asaf-quote-information {
  color: $body-color;
  background: url(../Images/quote-icon.png) no-repeat fixed center;
}

.asaf-quote-image {
  position: relative;
}

@include media-breakpoint-down(lg) {
  .asaf-quote-icon-right,
  .asaf-quote-icon-left {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .slick-arrow {
    display: none !important;
  }
}