.cluster {
  span {
    color: $asaf-white;
  }
}

.asaf-chapters,
.asaf-ambassadors {
  .gm-style {
    .gm-style-iw {
      top: 0 !important;
      left: 0 !important;
      background-color: transparent!important;
      padding: 0!important;
      border: none!important;
      box-shadow: 0 0 0 0!important;
      button {
        display: none!important;
      }
    }

    .gm-style-iw-d {
      overflow: hidden!important;
    }


    .chapter-popup,
    .ambassadors-1-popup,
    .ambassadors-2-popup {
      display: block !important;
      background-color: $asaf-white;
      box-sizing: border-box;
      border-radius: 120px;
      width: 220px;
      min-height: 220px;

      div {
        color: $asaf-grey;
        font-size: 1rem;
        text-align:center;
        font-weight: 500;
        font-family: "Palanquin"!important;
      }

      div.link {
        text-align:left;
        padding-left: 60px;
        color: $asaf-green;
        font-weight: 400;
        line-height: 1.3rem;
        a {
          color: $asaf-green;
          font-weight: 400;
        }
      }

    }

    .ambassadors-1-popup,
    .ambassadors-2-popup
    {
      .portrait {
        padding: 15px 0 5px 0!important;
      }
      div {
        line-height: 1.5rem;
        img.envelope {
          padding-top:10px;
        }
      }
    }

    .ambassadors-2-popup {
      border-radius: 80px;
      width: 340px!important;

      .left,
      .right {
        float: left;
        width: 160px;
      }

      .left {
        padding-left: 10px;
      }

      .right {
        padding-right: 10px;
      }

    }

  }
  .gm-style .chapter-popup div {

    img {
      margin-top: 40px;
      padding-bottom: 15px;
    }
  }
}




