.asaf-chapters {

  .asaf-chapter {
    h3 {
      font-size: 1.75rem;
      font-weight: 400;
    }

    .badge {

      margin-right: 5px;
      padding: 5px 20px 7px 20px;

      &.lightblue {
        background: $asaf-blue-light;
      }

      &.blue {
        background: $asaf-blue;
      }

      &.yellow {
        background: $asaf-yellow;
      }
    }
  }

  .separator {
    border-bottom: 2px solid $asaf-blue-light;
  }



}