.asaf-timeline {

  h3 {
    font-size: 2.5rem;
  }


  .asaf-timeline-events {
    background: url(../Images/timeline-dot.png) repeat-y fixed center;
    width: 770px;
    margin: 0 auto;
    padding-bottom: 30px;

    .asaf-timeline-event {

      color: $asaf-white;
      padding-top: 50px;

      h4 {
        font-size: 1rem;
        line-height: 1.2rem;
      }

      .year {
        height: 120px;
        width: 110px;
        float:left;
        font-size: 1.563rem;
        font-weight: 600;
        padding-top: 30px;
        margin-top: 10px;
      }

      .dot {
        float:left;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        margin-top: 52px;
      }

      .dots {
        float:left;
        width: 25px;
        height: 20px;
        margin: 60px 8px 0 8px;

      }

      .content {
        float:left;
        width: 330px;
        padding-top: 10px;
        text-align: left;
        .text {
          border-radius: 20px;
          p {
            line-height: 1.2rem;
          }
        }

        .image {
          border-radius: 20px;
          width: 330px;
          height: 120px;
          background-repeat: no-repeat;
        }

      }

      &.odd {
        padding-left: 224px;
      }

      &.even {
        padding-left: 4px;
      }


      &.green {
        .text,
        .dot {
          background: $asaf-green;
        }
        .dots {
          background: url(../Images/timeline-green-dot.png) repeat-x;
        }
        .year {
          background: url(../Images/timeline-item-year-background-green.png) no-repeat;
        }
      }

      &.yellow {
        .text,
        .dot {
          background: $asaf-yellow;
        }
        .dots {
          background: url(../Images/timeline-yellow-dot.png) repeat-x;
        }
        .year {
          background: url(../Images/timeline-item-year-background-yellow.png) no-repeat;
        }
      }

      &.blue {
        .text,
        .dot {
          background: $asaf-blue;
        }
        .dots {
          background: url(../Images/timeline-blue-dot.png) repeat-x;
        }
        .year {
          background: url(../Images/timeline-item-year-background-blue.png) no-repeat;
        }
      }

      &.blue-light {
        .text,
        .dot {
          background: $asaf-blue-light;
        }
        .dots {
          background: url(../Images/timeline-blue-light-dot.png) repeat-x;
        }
        .year {
          background: url(../Images/timeline-item-year-background-blue-light.png) no-repeat;
        }
      }
    }

  }



  &.yellow {
    background: $asaf-yellow;
  }

  &.blue {
    background: $asaf-blue;
  }

  &.blue-light {
    background: $asaf-blue-light;
  }

  &.green {
    background: $asaf-green;
  }

  @include media-breakpoint-down(md) {
    .asaf-timeline-event {
      margin-left: -40px;
    }
  }

}


@include media-breakpoint-down(sm) {
  .asaf-timeline-events {
    background: none!important;
  }
}
