.asaf-testimonial {

  &.yellow {
    background: $asaf-yellow-testimonials;
  }

  &.blue {
    background: $asaf-blue;
  }

  &.blue-light {
    background: $asaf-blue-light;
  }

  &.green {
    background: $asaf-green;
  }

  color: $asaf-white;



  .asaf-fa {

    opacity: 0.7;
    z-index: 99;
  }

  $asaf-quote-size: 180px;

  .asaf-quote-icon {
    width: $asaf-quote-size;
    height: $asaf-quote-size;
  }

  .asaf-quote-icon-background {
    position: absolute;
    top: 16rem;
    left: 10%;
    @include media-breakpoint-down(sm) {
      left: 25%;
    }
  }

  a {
    color: $asaf-white;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }



}

.asaf-testimonial-header {
  min-height: 90px;
}

.asaf-testimonial-text {
  font-style: italic;
}