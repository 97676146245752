


.asaf-news-element {

  h4 {
    color: $body-color;
    font-size: 1.75rem;
    font-weight: 400;

  }

  background: inherit;

  &:hover {
    background: #fff;
  }

  background: rgba(245,185,22,0.05);
  padding: 2rem;

  .fa-angle-right::before,
  .fa-external-link::before,
  .fa-envelope::before,
  .fa-arrow-to-bottom
  {
    color: $asaf-green;
    padding-right: 0.4rem;
    font-size: 1rem;
    font-weight: 300;
  }

  .fa-arrow-to-bottom {
    padding-right: 0.5rem;
    padding-left: 0.15rem;
  }
}

.badge {
  color: #fff;

  &.asaf-news-category {

    margin-right: 5px;

    &.main, &.even {
      background: $asaf-blue;
    }

    &.other, &.odd {
      background: $asaf-yellow;
    }
  }


  &.asaf-news-tag {

    margin-right: 5px;
    padding: 5px 20px 7px 20px;

    &.lightblue {
      background: $asaf-blue-light;
    }

    &.blue {
      background: $asaf-blue;
    }

    &.yellow {
      background: $asaf-yellow;
    }
  }

}

.asaf-news-show-all {
  color: $primary;
  a:hover {
    color: $primary;
  }
}

.asaf-news-element-detail {
  background: rgba(245,185,22,0.05);
  &:hover {
    background: $asaf-white;
  }
}

.asaf-news-element-authorname {
  color: $asaf-grey;
  a {
    color: $asaf-grey;
  }

  .fa-envelope::before {
    color: $asaf-green;
    font-size: 20px;
  }
}

.asaf-news-element-image-slider-wrapper {
  img {
    max-width: 100%;
    padding: 2%;
  }
  background: #fff;
}

.asaf-news-item-hr {
  border-top: 1px solid $asaf-grey;
}

.asaf-news-element-socialicons, .asaf-news-element-print {
  font-size: 1.25rem;
  a {
    color: $asaf-grey;
  }
  i {
    margin-right: 0.25rem;
  }
}

.asaf-read-more-toggle {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background: url(../Images/show-all-items.png) no-repeat;
  &:hover {
    cursor: pointer;
    background: url(../Images/show-all-items-hover.png) no-repeat;
  }
}

.asaf-news-element-details, .asaf-news-list-of-definitions {
  h3 {
    font-size: 1.625rem;
    line-height: 2.813rem;
    color: $asaf-blue-light;
    font-weight: 500;
  }

}