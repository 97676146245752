.asaf-event-item {
  h2 {
    color: $body-color;
    font-size: 1.875rem;
  }

  img {
    border-radius: 50%;
  }

}

.asaf-event-item-text {
  width: 100%;
  h2 {
    color: $asaf-grey;
    font-size: 1.75rem;
    font-weight: 400;
  }
}

.asaf-event-item-header {
  width: 100%;
  position: relative;
  img {
    margin-right: 10rem;
  }
}





.asaf-event-item-date__daymonth {
  margin: 60px 0 0 0;
  font-size: 2rem;
  line-height: 2.7rem;
  font-weight: 500;
}


.asaf-event-item-header {
  .asaf-event-item-date__daymonth {
    font-size: 1.8rem;
  }
}

.asaf-event-item-date__daymonth_smaller {
  margin: 60px 0 0 0;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
}
.asaf-event-item-date__year {
  font-size: 1.5rem;
  line-height: 2.7rem;
  font-weight: 500;
}

.asaf-event-search-result-item-date {

  .asaf-event-item-date__daymonth,
  .asaf-event-item-date__daymonth_smaller {
    margin: 90px 0 0 0;
  }

  .asaf-event-item-date__year {
    line-height: 3.8rem;
  }
}


/*
.asaf-event-item-date__day {
  margin: 70px 0 0 0;
  font-size: 3.125rem;
  line-height: 4.7rem;
  font-weight: 500;
}

.asaf-event-item-date__monthyear {
  line-height: 1.125rem;
  font-size: 1.438rem;
  font-weight: 500;
}*/

.asaf-event-date-small {
  min-height: 200px;
  min-width: 200px;
  position:absolute;
  top: 120px;
  left: 50%;
  color: $asaf-white;
  background: url(../Images/event-date-background-small.png) no-repeat!important;
  /*
  .asaf-event-item-date__day {
    margin-top: 40px;
    line-height: 4.2rem;
  }*/
}

.asaf-event-item-read-more-toggle {
  display: inline-block;
  color: $primary;

  &:hover {
    cursor: pointer;
  }
}

.asaf-event-item-map-header-icon {
  margin-top: 1rem;
  margin-right: 0.75rem;
}

.asaf-event-item-date {
  background: $primary;
}

.asaf-ics-export {
  a {
    color: #fff !important;
  }

  @include media-breakpoint-down(md) {
    justify-content: inherit !important;
  }
}

.asaf-ics-export-button {
  position: relative;
  top: 1rem;
}

.asaf-event-item-date {
  color: #fff;
}



.asaf-event-item-map-header {
  min-height: 90px;
  background: $asaf-blue-light;
  color: #fff;

  p {
    margin: 0;
    padding: 0;
    line-height:1.25rem;
    font-size: 1.125rem;
  }
}

.asaf-event-item-additional-infos {
  background: none;
}

.asaf-event-item-additional-infos-table {
  td {
    padding: 0 1rem 0 0;
    white-space: nowrap;
  }
}

.asaf-event-organizer {
  background: #fff;
}

@include media-breakpoint-down(md) {
  .asaf-event-item-map-header-item {
    padding: 1rem;
    width: 50%;
  }
  .asaf-event-item-additional-infos {
    .col-md-3 {
      margin-bottom: 1rem;
    }
  }
  .asaf-event-item-map-header-icon {
    .fa-3x {
      font-size: 1rem;
    }
  }
  .asaf-ics-export-button {
    top: 1.2rem;
  }
}