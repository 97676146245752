@media print {
  .navbar,
  .dropdown,
  .asaf-breadcrumb,
  .asaf-page-footer,
  .asaf-to-top,
  .asaf-nav-mobile,
  .asaf-navbar-toogle,
  .asaf-navbar-transparent,
  .asaf-dau-menu-text,
  .asaf-news-search-form,
  .asaf-news-element-socialicons,
  .asaf-news-element-print,
  .asaf-event-search-form,
  .asaf-event-item-header,
  .asaf-read-more-toggle,
  .asaf-event-item-map,
  .asaf-quote-icon,
  .asaf-header-waves-wrapper,
  .asaf-schedule-slider,
  .asaf-become-part,
  .coordination-board-archive {
    display: none;
  }
  .collapse {
    display: block !important;
  }
  main {
    img {
      margin-bottom: 1rem;
      width: 400px;
      height: auto;
    }

    .asaf-testimonial-image, .asaf-quote-image, .coordination-board-member-image {
      img {
        width: 200px;
        height: auto;
      }
    }
  }
  .shadow {
    box-shadow: none !important;
  }
}