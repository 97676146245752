.asaf-event-search-form {

  background: $asaf-blue-light;
  color: $asaf-white;

  input, select {
    margin-bottom: 0.5rem;
  }

  .asaf-button-search {
    width: 100%;
    padding: 0.4rem;
    font-weight: 500;
  }

  .form-control {
    background: $asaf-blue-lightest;
    color: $asaf-white;
    font-weight: 500;
  }

  .asaf-search-form-check label {
    font-weight: 500;
  }

  .form-control::placeholder {
    color: $asaf-white!important;
    font-weight: 500;
  }

}

.asaf-search-form-check {
  input {
    margin-top: 0.5rem;
    width: 25px;
    height: 25px;
  }

  label {
    margin: 0.5rem 0 0 1rem;
  }
}

.asaf-event-search-result-item {
  h2 {
    color: $asaf-grey;
    font-size: 1.75rem;
    font-weight: 400;
  }

  padding-bottom: 1rem;
  border-bottom: 2px solid $asaf-blue-light;

  .fa-angle-right::before {
    color: $asaf-green;
    padding-right: 0.2rem;
    font-size: 1rem;
  }

  .fa-clock,
  .fa-map-marker-alt {
    padding-right: 5px;
  }

  .badge {
    background: $asaf-blue;
    font-size: 0.8rem;
    font-weight: 400;
    margin-right: 5px;
    padding: 7px 20px 7px 20px;



    &.asaf-events-location {
      background: $asaf-blue;
    }
    &.asaf-events-time {
      background: $asaf-yellow;
    }
    &.asaf-events-category {
      background: $asaf-blue-light;
    }
  }

}

.asaf-event-search-result-item-date, .asaf-event-search-result-item-address {
  color: #fff;
}

.asaf-hidemap-1 {
  display: none;
}


.asaf-event-search-result-item-date {
  min-height: 280px;
  background: url(../Images/event-date-background.png) no-repeat!important;
}

@include media-breakpoint-down(md) {
  .asaf-event-search-result-item-date {

    min-width: 280px;
  }
  .asaf-event-search-result-item-date__day {
  }
}
