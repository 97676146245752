// normal headers in text (not in special elements)
h2 {
  color: $asaf-blue;
  font-weight: 500;
  font-size: 1.563rem;
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;
}

h4 {
  font-size: 1.15rem;
  font-weight: 500;
}

// font weights
.asaf-font-family-semi-bold {
  font-weight: 600;
}

.asaf-font-family-medium {
  font-weight: 500;
}

.asaf-font-family-regular {
  font-weight: 400;
}

.asaf-font-family-light {
  font-weight: 300;
}

// text colors
.asaf-blue {
  color: $asaf-blue-light !important;
}

.asaf-black {
  color: $body-color !important;
}

/* due to the fact that the "designer" is unable to follow a stringent layout of the hierarchy of the headings: here another supi-dupi special class with !important... */
.asaf-working-groups-event-header {
  h3, h2 {
    font-weight: 600 !important;
  }
}