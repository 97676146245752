.asaf-navbar {
  .navbar {
    padding: 30px 15px 0px 0;
    ul {
      padding-top: 30px;
      ul {
        padding-top: 10px;
        margin-top: 10px;
        ul {
          padding-top: 5px;
          margin-top: 0px;
          padding-bottom: 5px;
        }
      }
    }
  }


  -webkit-box-shadow:0px 2px 2px #ddd;
  -moz-box-shadow: 0px 2px 2px #ddd;
  box-shadow:0px 2px 2px #ddd;


  height: $navbar-height;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;

  background: $asaf-white;
  font-weight: 500;
  font-size: 1.3rem;




  .navbar-nav {

  }

  .nav-item + .nav-item::before {
    content: "|";
    color: $asaf-blue;
    padding-right: 1rem;
  }

  .nav-item.oc-community a.nav-link {
    color: $asaf-green;
    padding-left: 0.3rem;
  }

  .nav-link {
    display: inline-block;
  }

  a.nav-link {
    text-transform: uppercase;
    font-size: 1rem;
  }

  a.nav-link:hover, a.nav-link.active {
    border-bottom: 2px solid #fff;
    padding-bottom: 2px;
  }
  border-top: 8px solid $asaf-blue-topborder;

  .nav-item.dropdown a:after {
    content: '\f107';
    color: #fff;
    font-family: "Font Awesome 5 Pro";
    padding-left: 5px;
  }

  .dropdown-menu {
    border-radius: 0;
    border: none;
    a.dropdown-item {
      color: $asaf-grey;
    }
    a.dropdown-item:hover {
      color: $asaf-green;
    }
  }

  .dropdown-menu:before {
    content: " ";
    display: block;
    position: absolute;
    height: 25px;
    top: -25px;
    left: 0;
    right: 0;
  }

  .dropdown-menu a:after {
    content: '' !important;
  }

  .dropdown-menu-2 {
    background: $asaf-green!important;
    top: 0;
    left: 100%;
    box-shadow: none!important;
    a.dropdown-item {
      color: $asaf-white;
    }
    a.dropdown-item:hover {
      color: $asaf-green;
    }
  }

  ul.navbar-nav {
    .dropdown-menu {
      -webkit-box-shadow: 1px 1px 1px 1px #ddd;
      -moz-box-shadow:    1px 1px 1px 1px #ddd;
      box-shadow:         1px 1px 1px 1px #ddd;
    }
  }
}



.primary-logo {
  display:block;
}
.secondary-logo {
  display:none;
}


.scrolled {
  .asaf-navbar {
    height: $navbar-height-scrolled;
    .navbar {
      padding-top: 0;
    }
  }
  .asaf-logo {
    margin: 8px 0 0 0;
  }
  .primary-logo {
    display:none;
  }
  .secondary-logo {
    display:block;
  }

}

.asaf-logo {

  position: absolute;
  margin-top: 30px;
  margin-left: 1rem;
  width: 199px;
  height: 84px;
  background: #fff;
  z-index: 888;


  @media only screen and (max-width: 1119px) {
    .primary-logo {
      display: none;
    }
    .secondary-logo {
      display: block;
    }
  }

  @media only screen and (max-width: 890px) {
    margin-left: inherit;
  }

  @include media-breakpoint-down(sm) {
    width: 230px;
  }
}

@media only screen and (max-width: 1279px) {
  .asaf-event-date-slider {
    margin-left: 10%;
    .text-center {
      padding-right: 5rem;
    }
  }
}




li.asaf-community {
  margin-top: -5px;
  padding: 5px 15px;
  border: 2px solid #fff;

  &:hover {
    background: #fff;

    a.nav-link {
      background: $asaf-green;
      color: $asaf-blue !important;
    }
  }

  a.nav-link:hover {
    border-bottom: 2px solid #fff;
    padding-bottom: 6px;
  }
}





