

#asaf-nav-mobile {
  .social-media {
    i {
      font-size: 1.3rem;
      padding: 0.5rem;
      margin-right: 1rem;
      background: $asaf-green;
      display: block!important;
    }
  }
}


.asaf-side-icons,
{

  .search,
  .mail,
  .social-media,
  .communication-kit {

    a:hover {
      text-decoration: none!important;
    }

    position: fixed;
    right: 0 !important;
    z-index: 999;
    background: $asaf-green;
    i {
      color: $asaf-white;
      font-weight: 300;
      padding: 1.6rem;
    }
  }

  .search {

    top: 280px;
    .form {
      display:none;
    }
    input {
      color: $asaf-white;
      background: $asaf-green;
      height: 70px;
      border: none;
      padding-left: 70px;
      width: 300px;
      overflow: hidden;
    }
    i {
      font-size: 1.3rem;
    }
    i.search-active {
      position: absolute;
      top: 50%;
      left: 0px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

  }

  .mail {
    top: 360px;
    i {
      font-size: 1.3rem;
    }
  }

  .social-media {
    top: 440px;

    i.icon-social-media {
      padding: 1.6rem 1.4rem;
    }

    i.icon-separator {
      padding: 1.6rem 1.4rem 1.6rem 0rem;
    }
    i.icon-twitter {
      padding: 1.6rem 0rem 1.6rem 0rem;
    }
    i.icon-facebook {
      padding: 1.6rem 0.3rem;
    }
    i.icon-youtube {
      padding: 1.6rem 0.2rem;
    }
    i.icon-instagram {
      padding: 1.6rem 1.4rem 1.6rem 0.5rem;
    }

    .icon-social-media img {
      margin: 15px 0;
      cursor: pointer;
    }

    .icon-toogle {
      display: none;
    }

  }

  .communication-kit {
    top: 520px;
    background: #114a8a!important;
    width: 72px;
    height: 173px;


    a {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      transform:rotate(180deg);
      color: $asaf-white;
      text-decoration: none;
      display: block;
      width: 72px;
      height: 173px;
      padding: 16px 22px 0 10px;
    }



  }

  .communication-kit.sys-lang-fr {

    height: 190px;

    a {
      padding: 0 22px 0 10px;
    }
  }


}

.pagets__start {
  .asaf-side-icons {
    .search {
      top: 200px;
    }
    .mail {
      top: 280px;
    }
    .social-media {
      top: 360px;
    }
    .communication-kit {
      top: 440px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .asaf-side-icons {
    display: none;
  }
}


