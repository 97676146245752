.asaf-ambassador {

  min-height: 320px;

  &.yellow {
    background: $asaf-yellow;
  }

  &.blue {
    background: $asaf-blue;
  }

  &.blue-light {
    background: $asaf-blue-light;
  }

  &.green {
    background: $asaf-green;
  }

  color: $asaf-white;

  a {
    color: $asaf-white;
  }


  .asaf-fa {
    opacity: 0.7;
    z-index: 99;
  }



}


.asaf-ambassadors {

  .asaf-ambassador-header {
    line-height: 1.5rem;
  }

  .modal-dialog {
    max-width: 800px;
  }

  .modal-header {
    border: none;
    .close {
      font-size: 1.5rem;
      margin-right: -0.5rem;
    }
  }

  .modal-content {
    border: none!important;
    border-radius: 0!important;
    color: $asaf-white;

    &.yellow {
      background: $asaf-yellow;
    }

    &.blue {
      background: $asaf-blue;
    }

    &.blue-light {
      background: $asaf-blue-light;
    }

    &.green {
      background: $asaf-green;
    }
    a {
      color: $asaf-white;
    }



  }
}