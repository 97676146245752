.arabic {



  h2 {
    text-align: right!important;
  }

  .asaf-logo {
    margin-right: -8rem!important;
  }

  .asaf-navbar-toogle {
    left: 3rem;
  }

  /*
  .asaf-full-width-container {
    left: 65%!important;
  }*/

  .asaf-accordion {

    h3 {
      text-align: right!important;
    }
  }

  .asaf-navbar .nav-item + .nav-item::before {
    padding-left: 1rem;
  }

  .asaf-quotes {
    .slick-next {
      right: 24rem!important;
    }
    .slick-prev {
      right: 11rem!important;
    }
  }

  .asaf-quotes-wrapper {
    .asaf-quote-icon-right {
      right: rem-calc(-45)!important;
    }
    .asaf-quote-icon-left {
      left: rem-calc(-20)!important;
    }
  }

  .asaf-footer-newsletter-form {
    .checkbox-label {
      width: 315px!important;
    }
  }


  @media screen and (max-width: 1200px) {
    .asaf-logo {
      margin-right: 0!important;
      margin-top: 2.5rem;
    }
  }

  #powermail_hp_wrapper {
    margin-right: -99999px!important;
  }

  .cluster {
    img {
      left:0;
    }
  }


}